<template>
  <div class="detailsMainBlock" v-if="this.getScanOrderDetails">
    <div class="patientInfoBlock">
      <div class="subHeader">
        <img src="@/assets/images/idicon.svg" alt="icon" class="patient-icon" />
        <h3 class="title">Personal Details</h3>
      </div>
      <div class="patientDetails">
        <el-row :gutter="30">
          <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
            <div
              class="input-value"
              v-if="
                this.getScanOrderDetails &&
                this.getScanOrderDetails.patient_info &&
                this.getScanOrderDetails.patient_info.first_name
              "
            >
              <h4 class="name">First Name</h4>
              <p class="value">
                {{ this.getScanOrderDetails.patient_info.first_name }}
              </p>
            </div>
          </el-col>
          <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
            <div
              class="input-value"
              v-if="
                this.getScanOrderDetails &&
                this.getScanOrderDetails.patient_info &&
                this.getScanOrderDetails.patient_info.last_name
              "
            >
              <h4 class="name">Last Name</h4>
              <p class="value">
                {{ this.getScanOrderDetails.patient_info.last_name }}
              </p>
            </div>
          </el-col>
          <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
            <div
              class="input-value"
              v-if="
                this.getScanOrderDetails &&
                this.getScanOrderDetails.patient_info &&
                this.getScanOrderDetails.patient_info.date_of_birth
              "
            >
              <h4 class="name">Date of Birth</h4>
              <p class="value">
                {{
                  formatDate(
                    this.getScanOrderDetails.patient_info.date_of_birth
                  )
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
            <div
              class="input-value"
              v-if="
                this.getScanOrderDetails && this.getScanOrderDetails.order_id
              "
            >
              <h4 class="name">Order Id</h4>
              <p class="value">
                {{ this.getScanOrderDetails.order_id }}
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="patientInfoBlock pt-10">
      <div class="subHeader">
        <img
          src="@/assets/images/fileicon.svg"
          alt="icon"
          class="patient-icon"
        />
        <h3 class="title">Accession Details</h3>
      </div>
      <div class="patientDetails">
        <el-row :gutter="30">
          <el-col :xl="6" :lg="7" :md="8" :sm="12" :xs="12">
            <div
              class="input-value"
              v-if="
                getScanOrderDetails &&
                getScanOrderDetails.lab &&
                getScanOrderDetails.lab.name
              "
            >
              <h4 class="name">Laboratory </h4>
              <p class="value">{{ getScanOrderDetails.lab.name }}</p>
            </div>
          </el-col>
          <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
            <div
              class="input-value"
              v-if="getScanOrderDetails && getScanOrderDetails.collection_date"
            >
              <h4 class="name">Collection Date & Time</h4>
              <p class="value timevalue">
                {{
                  momentWithTimezone(
                    getScanOrderDetails.collection_date,
                    "MM-DD-yyyy"
                  )
                }}
                <span class="time">
                  {{
                    momentWithTimezone(
                      getScanOrderDetails.collection_date,
                      "hh:mm A"
                    )
                  }}
                </span>
              </p>
            </div>
          </el-col>
          <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
            <div class="input-value">
              <h4 class="name">Received Date & Time</h4>
              <p class="value timevalue">
                {{ momentWithTimezone(new Date(), "MM-DD-yyyy") }}
                <span class="time">
                  {{ momentWithTimezone(new Date(), "hh:mmA") }}
                </span>
              </p>
            </div>
          </el-col>
          <el-col :xl="6" :lg="5" :md="8" :sm="12" :xs="12">
            <div
              class="input-value"
              v-if="getScanOrderDetails && getScanOrderDetails.case_types"
            >
              <h4 class="name">Case Type</h4>
              <ul>
                <li
                  v-for="(caseType, index) of getScanOrderDetails.case_types"
                  :key="index"
                >
                  {{ caseTypeMap[caseType] }}
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
export default {
  name: "PatientCard",
  data() {
    return {
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
    };
  },
  computed: {
    ...mapGetters("orders", ["getScanOrderDetails"]),
  },
};
</script>
